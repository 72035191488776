import React from 'react'
import { Link } from 'gatsby'

import SEO from '../../components/seo'
import { Deco, default as Layout, Header, MainWithMenu, Section } from '../../components/layout'
import { ApplyAndWhatsNextSections, PositionIcon } from '../../components/pages/CareersPage'
import { Nav } from '../../components/nav'
import { LdJsonJobPosting, HeaderLink } from '../../components/semanticdata'
import Analytics from '../../components/analytics'

const CareerPositionPage = () => (
  <Layout className="bg-primary-1 text-primary-3">
    <Analytics />
    <SEO
      title="Senior Web Frontend Developer"
      pageUrl="/careers/seniorweb/"
      type="article"
      cardImage="/img/tumbacardjobs1.png"
      description="If you have an eye for details, you cannot stand mediocre UX, you never give up until the presentation is smooth and polished, Let's Talk!"
    />
    <LdJsonJobPosting
      title="Senior Web Frontend Developer"
      description="If you have an eye for details, you cannot stand mediocre UX, you never give up until the presentation is smooth and polished, Let's Talk!"
      publishDate="2024-08-02T14:47:04.000Z"
      minSalary="6000.00"
      maxSalary="8500.00"
    />
    <MainWithMenu className="relative grid">
      <Header className="z-20 pt-6 pb-24">
        <HeaderLink className="text-primary-2" />
        <Link to="/careers/">
          <div className="py-12 text-left">
            <h1 className="py-4 text-4xl lg:text-5xl text-primary-3 text-shadow">Careers</h1>
          </div>
        </Link>
      </Header>
      <Deco className="z-10 bg-primary-2" decoType={2} before after gridRow={2} />
      <Section className="z-20 lg:mr-48" gridRow={2}>
        <PositionIcon className="w-12 sm:w-20 text-primary-1 fill-current" />
        <h2 className="text-3xl lg:text-4xl text-primary-1">Senior Web Frontend Developer</h2>
        <div className="mt-4 mb-16 text-lg lg:text-xl tracking-normal">
          <p className="sm:mt-6 font-normal">
            Are you an experienced Front-end Developer with a passion for supporting people and standardizing processes? Do you want to work on projects that are at the heart of the latest trends in{' '}
            <strong className="text-primary-1 font-normal">green tech</strong>? Keep reading…
          </p>
          <p className="sm:mt-6">All about the role</p>
          <p className="sm:mt-6 font-normal">
            Join our team in creating the ultimate
            <strong className="text-primary-1 font-normal"> fleet management solution from the ground up</strong>! Together, we'll build a robust, secure, and scalable system that prioritizes availability. We'll take a forward-thinking approach to define and
            execute the design and development process in iterations, and <strong className="text-primary-1 font-normal"> leverage the latest web technologies </strong>
            to deliver an unmatched user experience.
          </p>

          <p className="sm:mt-6 font-normal">
            <em>What we are primarily looking for is a track record of building things that work and passion to make a better product.</em>
          </p>
        </div>
        <div className="grid gap-12 grid-cols-1 lg:grid-cols-2">
          <div>
            <h3 className="text-xl lg:text-2xl text-primary-1">We're counting on you to:</h3>
            <div className="mt-4 text-lg lg:text-xl font-normal tracking-normal">
              <ul className="pl-10 list-disc">
                <li className="mt-4">
                  <strong className="text-primary-1 font-normal">Implement</strong> high-quality, reusable and scalable code.
                </li>
                <li className="mt-4">
                  <strong className="text-primary-1 font-normal">Collaborate</strong> with our team to design and implement responsive user interfaces that align with our UI/UX principles.
                </li>
                <li className="mt-4">
                  <strong className="text-primary-1 font-normal">Anticipate and resolve</strong> issues and problems that arise, suggesting alternative approaches and solutions.
                </li>
                <li className="mt-4">
                  <strong className="text-primary-1 font-normal">Participate</strong> in formal and informal discussions, code reviews, design reviews and technical presentations.
                </li>
                <li className="mt-4">
                  <strong className="text-primary-1 font-normal">Break down features</strong> into easy to estimate tasks and take them through all their stages in a predictive manner with attention to details.
                </li>
                <li className="mt-4">
                  <strong className="text-primary-1 font-normal">Identify opportunities</strong> for improvement of the product.
                </li>
                <li className="mt-4">
                  <strong className="text-primary-1 font-normal">Discover and evaluate</strong> new technologies for implementation in order to maximize development and product efficiency.
                </li>
              </ul>
            </div>
          </div>
          <div>
            <h3 className="text-xl lg:text-2xl text-primary-1">Core Requirements</h3>
            <div className="mt-4 text-lg lg:text-xl font-normal tracking-normal">
              <ul className="pl-10 list-disc">
                <li className="mt-4">
                  <strong className="text-primary-1 font-normal">Proficient</strong> in modern Web technologies and principles.
                </li>
                <li className="mt-4">
                  <strong className="text-primary-1 font-normal">4+ years</strong> of frontend development using <strong className="text-primary-1 font-normal">React</strong> and its ecosystem. Candidates with proficiency in other frameworks will be
                  considered.
                </li>
                <li className="mt-4">
                  <strong className="text-primary-1 font-normal">In-depth knowledge</strong> and solid experience with <strong className="text-primary-1 font-normal">HTML5</strong>, <strong className="text-primary-1 font-normal">CSS/SASS</strong>,{' '}
                  <strong className="text-primary-1 font-normal">JavaScript</strong>, <strong className="text-primary-1 font-normal">Typescript</strong>.
                </li>
                <li className="mt-4">
                  <strong className="text-primary-1 font-normal">Experience with CesiumJS</strong> will be considered as a great advantage!
                </li>
                <li className="mt-4">
                  <strong className="text-primary-1 font-normal">Good understanding</strong> of Functional Programming, Object-Oriented Programming, Design Patterns & Principles.
                </li>
                <li className="mt-4">
                  A sense of good <strong className="text-primary-1 font-normal">UI & UX</strong> while maintaining a strong focus on performance, semantics and security.
                </li>
                <li className="mt-4">Self-organized, proactive and good communicator.</li>
                <li className="mt-4">Able to look through the customer lens.</li>
                <li className="mt-4">Help, mentor and guide other team members.</li>
                <li className="mt-4">
                  Some backend experience in creating or maintaining RESTful APIs is <strong className="text-primary-1 font-normal">a plus</strong>.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </Section>
      <ApplyAndWhatsNextSections position="Senior Web Frontend Developer" active={false} />
      <Nav gridRow={7} className="z-20 text-primary-2" />
    </MainWithMenu>
  </Layout>
)

export default CareerPositionPage
